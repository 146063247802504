<template>
  <div
    class="mx-4 mb-2 grid grid-cols-1 gap-2 justify-around"
  >
    <auth-button
      v-for="provider in providers"
      :key="provider"
      :name="provider.name"
      :url="provider.url"
      :query="query"
      path="socialite/"
      @click="emits('submitted', provider.name)"
    />
  </div>
</template>

<script setup>
import AuthButton from '@app/components/auth/AuthButton.vue'

defineProps({
  // List of providers
  // (@shared/config/socialiteProviders.json)
  providers: {
    type: Array,
    default: () => [],
  },
  // Route's query (if exist)
  query: {
    type: [Object, Array],
    default: () => [],
  },
})

/**
 * Send action to parent on click
 */
const emits = defineEmits([
  'submitted',
])
</script>
